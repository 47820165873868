* {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th, td {
  text-align: left;
  vertical-align: top;
}

img, iframe {
  border: 0;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

* {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  box-sizing: border-box;
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-variant-numeric: lining-nums;
}

html {
  height: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scroll-behavior: smooth;
  font-size: 16px;
  font-size: calc(14px + (2) * (100vw - 400px) / (860));
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-weight: normal;
  -webkit-text-size-adjust: 100%;
}
@media (min-width: 1260px) {
  html {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  html {
    font-size: 14px;
  }
}

body {
  position: relative;
  min-height: 100%;
  color: #272343;
  background-color: #FFFFFE;
  overflow-x: hidden;
}
body.pledge-open, body.nav-open {
  overflow: hidden;
}

::selection {
  color: #fff;
  background-color: rgba(3, 42, 255, 0.7);
}

picture {
  position: relative;
  z-index: 1;
  display: block;
  font-size: 0;
}
picture > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  vertical-align: middle;
  background-color: #fff;
}
picture > img:first-child:last-child {
  position: relative;
}
picture > img:first-child {
  z-index: -1;
  height: 100%;
  object-fit: cover;
}
picture > img:last-child {
  z-index: 0;
  transition: opacity 0.15s ease;
}

a {
  color: #272343;
}
a:hover {
  text-decoration: none;
}

p {
  line-height: 1.5em;
}
p:not(:last-child) {
  margin-bottom: 20px;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.site-header {
  position: relative;
  z-index: 10;
  padding: 0 20px;
}
.site-header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.site-header nav {
  display: flex;
  align-items: stretch;
  height: 70px;
}
.site-header .logo {
  display: block;
  margin-right: 20px;
}
.site-header .logo img {
  width: 120px;
  height: auto;
  vertical-align: middle;
}
.site-header .region {
  position: relative;
  align-self: center;
  margin: 0 10px 0 -10px;
}
.site-header .region span {
  position: absolute;
  top: 0;
  left: 14px;
  display: inline-block;
  padding: 0 5px;
  color: rgba(39, 35, 67, 0.5);
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0.92px;
  text-transform: uppercase;
  background-color: #fff;
  transform: translateY(-50%);
}
.site-header .region select {
  padding: 9px 40px 7px 14px;
  color: #272343;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0;
  appearance: none;
  background: #fff;
  border: 1px solid #DFDFDF;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.site-header .region::after {
  content: "";
  position: absolute;
  bottom: 11px;
  right: 15px;
  display: block;
  width: 7px;
  height: 19px;
  background-image: url(../svg/tumbler.svg);
  pointer-events: none;
}
.site-header nav:first-child {
  margin-left: -20px;
}
.site-header nav:first-child a:not(.logo) {
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 24px 20px 20px;
  color: #272343;
  font-size: 1.25rem;
  font-weight: 500;
  text-decoration: none;
  border-bottom: 4px solid #fff;
  transition: border-bottom-color 0.15s ease;
}
.site-header nav:first-child a:not(.logo):hover, .site-header nav:first-child a:not(.logo).active {
  border-bottom-color: #FFD803;
}
.site-header nav:last-child a:not(.btn) {
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 25px 10px 21px;
  color: #272343;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  border-bottom: 4px solid #fff;
  transition: border-bottom-color 0.15s ease;
}
.site-header nav:last-child a:not(.btn):hover, .site-header nav:last-child a:not(.btn).active {
  border-bottom-color: #FFD803;
}
.site-header .btn {
  margin-left: 10px;
  align-self: center;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.back {
  display: block;
  padding: 11px 10px;
  color: #272343;
  font-weight: bold;
  text-decoration: none;
  background-color: #E8E8EB;
}
.back:hover {
  text-decoration: underline;
}
.back .container {
  padding-left: 120px;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.business-header {
  padding: 41px 10px 35px;
  text-align: center;
  background-color: #F5F6F6;
}
.business-header .container {
  position: relative;
}
.business-header-type {
  display: inline-block;
  margin-bottom: 20px;
  padding: 5px 16px 3px;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #AEA9D4;
  border-radius: 100px;
}
.business-header time {
  display: block;
  margin-bottom: 10px;
  color: rgba(39, 35, 67, 0.5);
  font-size: 1.5rem;
}
.business-header h1 {
  max-width: 800px;
  margin: 0 auto 9px;
  font-size: 4rem;
  font-weight: bold;
}
.business-header-img {
  position: relative;
  z-index: 2;
  padding: 0 10px;
}
.business-header-img::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  display: block;
  width: 100vw;
  height: 50%;
  background-color: #F5F6F6;
}
.business-header-img picture {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.business-header-logos {
  position: absolute;
  top: 0;
  right: 0;
}
.business-header-logos svg {
  margin-left: 10px;
}
@media (max-width: 62em) {
  .business-header-logos {
    top: -5px;
  }
  .business-header-logos svg {
    width: auto;
    height: 30px;
  }
}
.business-body {
  padding: 20px 10px 100px;
}
.business-content {
  grid-column: span 4;
  padding-top: 65px;
}
@media (max-width: 62em) {
  .business-content {
    grid-column: span 12;
    padding-top: 20px;
  }
}
.business-content h3 {
  margin: 60px 0 20px;
  font-size: 1.125rem;
  font-weight: bold;
}
.business-content-desc {
  margin-right: -71px;
  font-size: 1.125rem;
}
@media (max-width: 62em) {
  .business-content-desc {
    margin-right: 0;
  }
}
.business-content-support {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.business-content-support a {
  display: flex;
  align-items: center;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  margin-bottom: 7px;
}
.business-content-support svg {
  vertical-align: middle;
  margin-right: 18px;
  width: 30px;
  height: 30px;
}
.business-content-social-distancing {
  list-style: none;
}
.business-content-social-distancing li {
  padding-left: 34px;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5em;
  background: url(../svg/check.svg) no-repeat 5px 5px;
}
.business-content-social-distancing li:not(:last-child) {
  margin-bottom: 5px;
}
.business-content-pledge {
  max-width: 440px;
  margin-top: 50px;
  padding: 20px;
  text-align: center;
  border: 4px solid #FFD803;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.business-content-pledge strong {
  display: block;
  margin: 20px 0 10px;
  font-size: 1.5rem;
  font-weight: 500;
}
.business-aside {
  grid-column: 6/-1;
}
@media (max-width: 62em) {
  .business-aside {
    grid-column: span 12;
  }
}
.business-aside picture {
  overflow: hidden;
  border-radius: 5px;
}
.business-aside figcaption {
  display: block;
  margin-top: 10px;
  font-size: 0.875rem;
}
.business-aside-imgs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  align-items: start;
}
@media (max-width: 62em) {
  .business-aside-imgs {
    grid-gap: 10px;
  }
}
.business-aside-video {
  position: relative;
  margin-top: 20px;
  padding-top: 56.25%;
  border-radius: 5px;
  overflow: hidden;
}
.business-aside-video video,
.business-aside-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.business-aside-map {
  margin-top: 20px;
}
.business-aside-map img {
  width: 100%;
  border-radius: 5px;
}
.business-aside-map figcaption {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.business-aside-map a {
  display: inline-block;
  margin-left: 10px;
  color: #272343;
  font-weight: bold;
  text-decoration: none;
}
.business-aside-map a:hover {
  text-decoration: underline;
}
.business-care-btn {
  margin-bottom: 10px;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.btn {
  display: inline-block;
  padding: 7px 18px 6px;
  color: #272343;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
  appearance: none;
  background-color: #FFD803;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.15s ease;
}
.btn:hover {
  opacity: 0.75;
}
.btn.wide {
  width: 100%;
  text-align: center;
}
.btn.large {
  padding: 13px 18px 12px 21px;
  font-size: 1.25rem;
}
.btn.dark {
  color: #FFD803;
  background-color: #272343;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.hero {
  position: relative;
  z-index: 2;
  padding: 90px 10px;
  background-color: #F5F6F6;
}
.hero picture {
  overflow: hidden;
}
.hero .grid {
  align-items: center;
}
.hero-logo {
  height: 100px;
  margin-bottom: 20px;
}
.hero-generic {
  color: #fff;
}
.hero-generic::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}
@media (max-width: 62em) {
  .hero-generic::before {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
  }
}
.hero-generic picture {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hero-generic picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-content {
  grid-column: span 5;
}
@media (max-width: 1400px) {
  .hero-content {
    grid-column: span 6;
  }
}
@media (max-width: 62em) {
  .hero-content {
    grid-column: span 12;
  }
}
.hero-content h1 {
  margin-bottom: 10px;
  font-size: 4rem;
  font-weight: bold;
  line-height: 1em;
}
.hero-content p {
  font-size: 1.125rem;
  font-weight: 500;
}
.hero-content a {
  color: inherit;
}
.hero-county .hero-content h1 {
  margin-bottom: 15px;
}
.hero-county .hero-content p {
  font-weight: normal;
}
.hero-county .hero-content .btn {
  margin-top: 20px;
}
.hero-about {
  grid-column: -4/-1;
  padding: 20px;
  color: #272343;
  text-align: center;
  background: #fff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 2;
}
@media (max-width: 1400px) {
  .hero-about {
    grid-column: -5/-1;
  }
}
@media (max-width: 62em) {
  .hero-about {
    grid-column: span 12;
  }
}
.hero-about img:first-child {
  width: 74px;
  height: 74px;
  margin-top: -57px;
}
.hero-about h2 {
  margin-bottom: 15px;
  font-size: 1.5rem;
  font-weight: 500;
}
.hero-about p {
  font-size: 0.875rem;
}
.hero-about input {
  display: block;
  width: 100%;
  margin-bottom: 6px;
  padding: 10px 0 8px;
  font-size: 0.875rem;
  text-indent: 13px;
  appearance: none;
  background: #fff;
  border: 1px solid #DFDFDF;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.hero-about button {
  margin-top: 11px;
  width: 100%;
}
.hero-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50vw;
}
.hero-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 62em) {
  .hero-img {
    position: relative;
    width: 100vw;
    margin: 40px 0 -90px -10px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.filter {
  position: sticky;
  top: 0;
  z-index: 3;
  padding: 0 10px;
  background-color: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}
@media (max-width: 62em) {
  .filter {
    padding: 0;
  }
}
.filter .container {
  display: flex;
  align-items: center;
}
@media (max-width: 62em) {
  .filter .container {
    display: block;
  }
}
.filter .container > label {
  position: relative;
  display: inline-block;
  padding: 18px 0 13px 20px;
}
@media (max-width: 62em) {
  .filter .container > label {
    display: block;
    width: 100%;
  }
  .filter .container > label:not(:last-of-type) {
    border-bottom: 1px solid #DADADA;
    border-right: none !important;
  }
}
.filter .container > label:first-child {
  padding-left: 0;
}
@media (max-width: 62em) {
  .filter .container > label:first-child {
    padding-left: 20px;
  }
}
.filter .container > label > span {
  display: block;
  color: rgba(39, 35, 67, 0.5);
  font-size: 0.813rem;
  font-weight: 500;
}
.filter input,
.filter select {
  width: 100%;
  padding: 5px 0;
  color: #272343;
  font-size: 1.25rem;
  font-family: "Proxima Nova", -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-weight: 500;
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
}
.filter select {
  padding-right: 30px;
}
@-moz-document url-prefix() {
  .filter select {
    margin-left: -5px;
  }
}
.filter-select {
  border-right: 1px solid #DADADA;
}
.filter-select::after {
  content: "";
  position: absolute;
  bottom: 20px;
  right: 10px;
  display: block;
  width: 7px;
  height: 19px;
  background-image: url(../svg/tumbler.svg);
  pointer-events: none;
}
.filter-search {
  min-width: 40%;
  flex-grow: 1;
}
@media (max-width: 62em) {
  .filter-search {
    display: inline-block !important;
    max-width: calc(100% - 100px);
  }
}
.filter-toggle {
  display: inline-flex;
  padding: 5px;
  background-color: #E4E4E4;
  border-radius: 5px;
}
.filter-toggle label {
  position: relative;
  display: inline-block;
  padding: 0;
}
.filter-toggle label:hover span {
  opacity: 1;
}
.filter-toggle input {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.filter-toggle span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  opacity: 0.4;
  transition: opacity 0.15s ease;
}
.filter-toggle input:checked + span {
  background: #fff;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  opacity: 1;
}
.filter-toggle svg {
  vertical-align: middle;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.leaflet-popup-content {
  margin: 0 !important;
}

.leaflet-popup-content-wrapper {
  padding: 0 !important;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px !important;
}

.leaflet-popup-tip {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.map {
  position: relative;
  z-index: 0;
  width: 100vw;
  height: calc(100vh - 77px);
}
.map-dot {
  display: block;
  width: 9px;
  height: 9px;
  background-color: #EB4025;
  border-radius: 50%;
}
.map-popup {
  color: #272343;
  text-decoration: none;
}
.map-popup img {
  width: 100%;
  border-radius: 5px 5px 0 0;
  vertical-align: middle;
}
.map-popup:hover h5 {
  text-decoration: underline;
}
.map-popup-content {
  display: block;
  padding: 10px 20px 20px;
}
.map-popup-content h5 {
  color: #272343;
  font-size: 1.25rem;
  font-weight: bold;
}
.map-popup-content p {
  margin: 0 !important;
  color: rgba(39, 35, 67, 0.5);
  font-size: 0.875rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.map-popup-services {
  display: block;
}
.map-popup-tag {
  display: inline-block;
  margin: 7px 3px 0 0;
  padding: 4px 13px 3px;
  font-size: 0.813rem;
  font-weight: 500;
  letter-spacing: 0.93px;
  text-transform: uppercase;
  border-radius: 50px;
}
.map-popup-type {
  color: #272343;
  background-color: #AEA9D4;
}
.map-popup-service {
  color: #665915;
  background-color: #FFD803;
}
.map-popup-external {
  display: block;
  padding: 10px 20px 10px 15px;
  color: #272343 !important;
  font-size: 0.875rem;
  font-weight: bold;
  text-decoration: none;
}
.map-popup-external svg {
  margin-top: -2px;
  margin-left: 6px;
  vertical-align: middle;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.results {
  position: relative;
  padding: 50px 10px;
}
.results .grid {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
@media (max-width: 62em) {
  .results .grid {
    grid-gap: 10px;
  }
}
.results-card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.results-card .map-popup-content {
  padding-top: 15px;
}
.results-spinner {
  position: sticky;
  z-index: 3;
  left: 0;
  bottom: 0;
  width: 100vw;
  margin-top: 20px;
  padding: 50px 10px;
  text-align: center;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 100%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
.results-spinner.show {
  opacity: 1;
}
.results-spinner::before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 4px solid #FFD803;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: spin infinite 1s linear;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.articles {
  position: relative;
  padding: 100px 10px;
  background-color: #F5F6F6;
}
.articles .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 20px;
}
@media (max-width: 62em) {
  .articles .container {
    grid-gap: 10px;
  }
}
.articles-header {
  grid-column: 1/-1;
}
.articles-header h2 {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
}
.articles-header h2 + p {
  text-align: center;
}
.articles-header h3 {
  font-size: 2rem;
  font-weight: bold;
}
.articles-header p {
  margin-bottom: 40px;
}
.articles-footer {
  grid-column: 1/-1;
  margin-top: 20px;
  text-align: center;
}
.articles-card {
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  text-decoration: none;
  overflow: hidden;
}
.articles-card-content {
  display: block;
  padding: 20px 30px;
}
.articles-card-content small {
  display: block;
  margin-bottom: 5px;
  color: rgba(39, 35, 67, 0.5);
  font-size: 0.875rem;
}
.articles-card-content strong {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.3em;
}
.articles-card:hover strong {
  text-decoration: underline;
}
.articles-spinner {
  background-image: linear-gradient(to bottom, rgba(245, 246, 246, 0) 0%, rgba(245, 246, 246, 0.9) 100%);
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.article {
  padding: 60px 10px;
}
.article .container {
  max-width: 920px;
}
.article-tag {
  color: #272343;
  background-color: #FFD803;
}
.article ul,
.article ol {
  margin: 0 0 20px 20px;
}
.article li {
  line-height: 1.5em;
}
.article li:not(:last-child) {
  margin-bottom: 10px;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.account {
  position: relative;
  z-index: 2;
  margin: 0 20px 100px;
}
@media (max-width: 62em) {
  .account {
    margin: 0 10px 100px;
  }
}
.account-header {
  position: relative;
  padding: 148px 20px 90px;
}
@media (max-width: 62em) {
  .account-header {
    padding: 148px 10px 90px;
  }
}
.account-header h1 {
  color: #fff;
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
}
.account-header::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: -200px;
  display: block;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
}
.account-header picture {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  right: 0;
  bottom: -200px;
}
.account-header img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.account-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: auto;
  max-width: 1160px;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 62em) {
  .account-container {
    display: block;
  }
}
.account-container.small {
  max-width: 920px;
}
.account-container.tiny {
  max-width: 460px;
}
.account-container h2 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
}
.account-container h3 {
  margin: 50px 0 10px;
  font-size: 1.25rem;
  font-weight: bold;
}
.account-container h3 + p {
  font-size: 0.875rem;
}
.account-content, .account-aside {
  width: 100%;
  max-width: calc(100% - 460px);
  padding: 70px 50px;
}
.account-content:not(:last-of-type), .account-aside:not(:last-of-type) {
  padding-bottom: 0;
}
.account-content:not(:first-of-type), .account-aside:not(:first-of-type) {
  padding-top: 0;
}
@media (max-width: 62em) {
  .account-content, .account-aside {
    max-width: 100%;
    padding: 20px;
  }
}
.account-content:first-child:last-child, .account-aside:first-child:last-child {
  max-width: 100%;
}
.account-aside {
  max-width: 460px;
  background-color: #F5F6F6;
}
@media (max-width: 62em) {
  .account-aside {
    max-width: 100%;
  }
  .account-aside.m-hide {
    display: none;
  }
}
.account-list {
  list-style: none;
}
.account-list a {
  display: block;
  padding: 5px 0;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.form {
  margin-top: 20px;
  border: none;
}
.form-success, .form-info, .form-notice, .form-error {
  display: block;
  margin: 20px 0;
  padding: 10px 15px;
  color: #0020cf;
  background-color: rgba(3, 42, 255, 0.12);
  border-radius: 5px;
}
.form-success p, .form-info p, .form-notice p, .form-error p {
  margin-bottom: 0;
}
.form-success p:first-child, .form-info p:first-child, .form-notice p:first-child, .form-error p:first-child {
  font-weight: bold;
}
.form-success p:first-child:last-child, .form-info p:first-child:last-child, .form-notice p:first-child:last-child, .form-error p:first-child:last-child {
  font-weight: 500;
}
.form-success:last-child, .form-info:last-child, .form-notice:last-child, .form-error:last-child {
  margin-bottom: 0;
}
.form-success {
  color: #456900;
  background-color: rgba(168, 255, 3, 0.2);
}
.form-notice {
  color: #695900;
  background-color: rgba(255, 216, 3, 0.2);
}
.form-error {
  color: #cf0042;
  background-color: rgba(255, 3, 83, 0.12);
}
.form .error {
  display: block;
  margin-top: 5px;
  color: #cf0042;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3em;
}
.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: -15px;
}
label + .form-row, .form-row + .form-row {
  margin-top: 0;
}
@media (max-width: 62em) {
  .form-row {
    display: block;
  }
}
.form-row.r2-1 {
  grid-template-columns: 1fr 1fr 1fr;
}
.form-row.r2-1 *:first-child {
  grid-column: span 2;
}
.form .label,
.form label {
  display: block;
  margin-top: 15px;
}
.form .label > span,
.form label > span {
  display: block;
  line-height: 1.4em;
}
.form .label > span:first-child,
.form label > span:first-child {
  font-weight: 500;
}
.form .label > span:not(:first-child),
.form label > span:not(:first-child) {
  margin-top: 5px;
  font-size: 0.875rem;
}
.form select,
.form textarea,
.form input:not([type=checkbox]):not([class*=multiselect]) {
  display: block;
  width: 100%;
  margin-top: 5px;
  padding: 12px 0;
  color: #272343;
  font-size: 1rem;
  text-indent: 13px;
  appearance: none;
  background: #fff;
  border: 1px solid #DFDFDF;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.form select:read-only,
.form textarea:read-only,
.form input:not([type=checkbox]):not([class*=multiselect]):read-only {
  background-color: #fafafa;
  outline: none;
}
.form textarea {
  padding: 12px 13px;
  text-indent: 0;
  resize: none;
  line-height: 1.4em;
}
.form textarea.mono {
  font-size: 0.875rem;
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  line-height: 1.5em;
}
@-moz-document url-prefix() {
  .form select {
    text-indent: 4.5px;
  }
}
.form .select {
  position: relative;
  display: block;
}
.form .select::after {
  content: "";
  position: absolute;
  bottom: 12px;
  right: 10px;
  display: block;
  width: 7px;
  height: 19px;
  background-image: url(../svg/tumbler.svg);
  pointer-events: none;
}
.form .checkbox {
  display: flex;
  align-items: center;
  padding: 15px 13px;
  background-color: #F5F6F6;
  border-radius: 5px;
}
.form .checkbox input[type=checkbox] {
  margin-right: 10px;
}
.form > button {
  display: block;
  width: 100%;
  margin-top: 15px;
  padding: 12px 13px 13px;
  color: #272343;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  appearance: none;
  background: #FFD803;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.15s ease;
}
.form > button:hover {
  background-color: #f8d100;
}
.form > button.dark {
  color: #fff;
  background-color: #272343;
}
.form > button.dark:hover {
  background-color: #332e57;
}
.form > a {
  display: block;
  margin-top: 15px;
  font-weight: 500;
  text-align: center;
}
.form-media {
  margin-top: 5px;
  list-style: none;
}
.form-media li {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #F5F6F6;
  border-radius: 5px;
  overflow: hidden;
}
.form-media li > input[type=file] {
  width: 0;
  height: 0;
  pointer-events: none;
}
.form-media li > span {
  display: block;
  width: 100%;
  flex-shrink: 9999;
  padding: 0 10px;
  font-size: 0.875rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.form-media li:not(:last-child) {
  margin-bottom: 10px;
}
.form-media picture,
.form-media img {
  width: 44px;
  height: 44px;
  border-radius: 5px;
  object-fit: cover;
}
.form-media input[type=file] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 0 !important;
  opacity: 0;
  cursor: pointer;
}
.form-media label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 0;
  padding: 10px;
  border: 1px dashed #DFDFDF;
  border-radius: 5px;
  cursor: pointer;
}
.form-media label > span {
  margin-top: 0 !important;
  font-size: 0.875rem;
  font-weight: normal;
}
.form-media button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
}
.form-media button svg {
  pointer-events: none;
}
.form-multiselect {
  margin-top: 5px;
  background: #fff;
  border: 1px solid #DFDFDF;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}
.form-multiselect-search {
  width: 100%;
  padding: 12px 0;
  color: #272343;
  font-size: 1rem;
  text-indent: 13px;
  appearance: none;
  background: none;
  border: none;
  border-radius: 5px 5px 0 0;
}
.form-multiselect-list {
  max-height: 350px;
  list-style: none;
  border-top: 1px solid #DFDFDF;
  overflow: auto;
}
.form-multiselect-list > li:first-child > label {
  padding-top: 12px;
}
.form-multiselect-list > li:last-child > label {
  padding-bottom: 12px;
}
.form-multiselect-list ul {
  list-style: none;
}
.form-multiselect-list ul label {
  padding-left: 33px;
}
.form-multiselect-list label {
  display: flex;
  align-items: center;
  margin-top: 0;
  padding: 5px 13px 7px;
  cursor: pointer;
}
.form-multiselect-list label[hidden] {
  display: none;
}
.form-multiselect-list label:hover {
  background-color: #fafafa;
}
.form-multiselect-list input {
  margin-right: 10px;
  margin-bottom: -3px;
}
.form-toggle-list {
  margin-top: 5px;
  list-style: none;
}
.form-toggle-list li {
  display: flex;
  align-items: start;
  padding: 12px 13px;
  background-color: #F5F6F6;
  border-radius: 5px;
}
.form-toggle-list li:not(:first-child) {
  margin-top: 2px;
}
.form-toggle-list div {
  flex-grow: 1;
  padding-left: 14px;
}
.form-toggle-list label {
  margin-top: 0;
  font-weight: 500;
}
.form-toggle-list input:not([type=checkbox]) {
  display: none !important;
}
.form-toggle-list input[type=checkbox] {
  margin-top: 1px;
}
.form-toggle-list input[type=checkbox]:checked + div input {
  display: block !important;
}
.form-count {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #999;
  font-size: 0.688rem;
  line-height: normal;
  pointer-events: none;
}
.form-count-wrap {
  position: relative;
  display: block;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.site-footer {
  padding: 80px 10px 40px;
  background-color: #fff;
}
.site-footer hr {
  margin: 80px auto 40px;
  max-width: 1400px;
  border: none;
  border-top: 1px solid #E4E4E4;
}
.site-footer-logo {
  grid-column: span 2;
  color: #272343;
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 1.2em;
  text-decoration: none;
}
@media (max-width: 62em) {
  .site-footer-logo {
    grid-column: span 4;
  }
}
@media (max-width: 48em) {
  .site-footer-logo {
    grid-column: span 12;
  }
}
.site-footer-logo span {
  display: block;
  width: 1em;
}
.site-footer-links {
  grid-column: span 2;
  list-style: none;
  font-size: 1.125rem;
}
@media (max-width: 62em) {
  .site-footer-links {
    grid-column: span 4;
  }
}
@media (max-width: 48em) {
  .site-footer-links {
    grid-column: span 12;
  }
}
.site-footer-links strong {
  display: block;
  margin-bottom: 15px;
}
.site-footer-links a {
  display: block;
  padding: 5px 0;
  text-decoration: none;
}
.site-footer-links a:hover {
  text-decoration: underline;
}
.site-footer-links svg {
  margin: -3px 10px 0 0;
  vertical-align: middle;
}
@media (max-width: 48em) {
  .site-footer-meta {
    grid-gap: 10px;
  }
}
.site-footer-meta div {
  grid-column: span 4;
}
@media (min-width: 48em) {
  .site-footer-meta div {
    text-align: center;
  }
  .site-footer-meta div:first-child {
    text-align: left;
  }
  .site-footer-meta div:last-child {
    text-align: right;
  }
}
@media (max-width: 48em) {
  .site-footer-meta div {
    grid-column: span 12;
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.about {
  position: relative;
  padding: 200px 10px;
  background-color: #F5F6F6;
}
@media (max-width: 62em) {
  .about {
    padding: 0 10px 50px;
  }
}
.about picture {
  position: absolute;
  top: 0;
  right: 50vw;
  bottom: 0;
  width: 50vw;
  overflow: hidden;
  grid-column: span 6;
}
.about picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 62em) {
  .about picture {
    position: relative;
    width: 100vw;
    right: 0;
    margin: 0 -10px;
    grid-column: span 12;
  }
}
.about-text {
  grid-column: -6/-1;
  padding: 50px 0;
}
@media (max-width: 62em) {
  .about-text {
    grid-column: span 12;
    padding: 20px 0 0;
  }
}
.about-text h1 {
  margin-bottom: 20px;
  font-size: 4rem;
  font-weight: bold;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.page {
  padding: 90px 10px;
  font-size: 1.125rem;
}
.page .container {
  max-width: 920px;
}
.page h1,
.page h2,
.page h3 {
  margin: 30px 0 20px;
  font-weight: bold;
}
.page h1:first-child,
.page h2:first-child,
.page h3:first-child {
  margin-top: 0;
}
.page h1 {
  font-size: 3rem;
}
.page h2 {
  font-size: 2rem;
}
.page h3 {
  font-size: 1.5rem;
}
.page blockquote {
  position: relative;
  margin: 40px 0;
  padding-left: 120px;
  font-size: 1.5rem;
  line-height: 1.5em;
  opacity: 0.6;
}
.page blockquote::before {
  content: "";
  position: absolute;
  top: 0;
  left: 60px;
  display: block;
  width: 24px;
  height: 100%;
  background-image: url(../svg/wave.svg);
  transform: translateX(-50%);
}
.page ul,
.page ol {
  margin: 0 0 20px 40px;
}
.page li {
  line-height: 1.5em;
}
.page li:not(:last-child) {
  margin-bottom: 10px;
}
.page a {
  background-color: rgba(255, 216, 3, 0.2);
  text-decoration-color: #FFD803;
  text-decoration-width: 2px;
  text-decoration-thickness: 2px;
}
.page a:hover {
  background-color: #FFD803;
  text-decoration: underline;
  text-decoration-color: #FFD803;
  text-decoration-width: 2px;
  text-decoration-thickness: 2px;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.pledge-overlay {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  padding: 20px;
  background-color: rgba(237, 237, 237, 0.8);
  backdrop-filter: blur(10px);
  overflow: auto;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}
.pledge-open .pledge-overlay {
  opacity: 1;
  pointer-events: all;
}
.pledge-modal {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
  max-width: 1400px;
  margin: auto;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
@media (max-width: 62em) {
  .pledge-modal {
    display: block;
  }
}
.pledge-modal h4 {
  margin: 30px 0 10px;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.5em;
}
.pledge-modal .btn.dark {
  max-width: 360px !important;
  padding: 10px 18px 8px;
  color: #fff;
  font-weight: 500;
}
.pledge-logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate3D(-50%, -20%, 0);
  max-width: 120px;
}
.pledge-content, .pledge-form {
  padding: 70px 50px;
}
@media (max-width: 62em) {
  .pledge-content, .pledge-form {
    padding: 50px 30px;
  }
}
.pledge-content {
  width: calc(100% - 480px);
}
@media (max-width: 62em) {
  .pledge-content {
    padding-top: 150px;
    width: 100%;
  }
}
.pledge-form {
  width: 480px;
  background-color: #F5F6F6;
}
@media (max-width: 62em) {
  .pledge-form {
    width: 100%;
  }
}
.pledge-form .checkbox {
  padding: 10px 13px;
  font-weight: bold;
  background-color: #fff;
}
.pledge-form .checkbox span {
  margin-top: 0 !important;
  font-size: 1rem !important;
}
.pledge-heading h2 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
}
.pledge-heading h3 {
  font-weight: bold;
}
.pledge-heading .small {
  font-size: 12px;
}
.pledge-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 15px;
  list-style: none;
  margin-top: 20px;
}
.pledge-list li {
  display: block;
  position: relative;
  padding: 20px;
  border: 5px solid;
  border-image: linear-gradient(#00a1dc, #832482, #e4114a, #fae017, #009447) 1;
}
.pledge-list li::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  opacity: 0.15;
  background-image: linear-gradient(to bottom, #00a1dc, #832482, #e4114a, #fae017, #009447);
}
.pledge-list h3 {
  margin-bottom: 20px;
  min-height: 52px;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.3em;
}
.pledge-list p {
  line-height: 1.2em;
  margin-bottom: 10px;
}
.pledge-supporters {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
.pledge-supporters img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinCentre {
  from {
    transform: translate3d(-50%, -50%, 0) rotate(0);
  }
  to {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova.woff2") format("woff2"), url("../fonts/ProximaNova.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-SemiBold.woff2") format("woff2"), url("../fonts/ProximaNova-SemiBold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"), url("../fonts/ProximaNova-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
/**
 * Round the given number to a set number of decimals
 * @see https://stackoverflow.com/a/29901280/550109
 */
/**
 * Convert the given PX size to REMs
 */
/**
 * $maxFontSize - The max font size (also the fallback)
 */
.container {
  display: block;
  width: 100%;
  max-width: 1400px;
  margin: auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
}
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .grid {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width: 34em) {
  .grid {
    grid-column-gap: 20px;
  }
}

.supporters {
  padding: 100px 20px;
  background-color: #fff;
}
.supporters .grid {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  align-items: center;
  justify-content: center;
  max-width: 1000px;
}
.supporters img {
  width: 100%;
  height: auto;
}